
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import CourseInfoDrawer from "@/layout/header/partials/course/CourseInfoDrawer.vue";
import CourseCopyDrawer from "@/layout/header/partials/course/CourseCopyDrawer.vue";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin.js";
import axios from "axios";
import { ElNotification } from "element-plus";
import { format } from "date-fns";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "new-entity-course",
  components: {
    axios,
    Swal,
    Form,
    Field,
    Datatable,
    CourseInfoDrawer,
    CourseCopyDrawer,
  },
  data() {
    return {
      componentKey: 0,
      tranche: [],
      association: [] as any,
      agent: [] as any,
      institutes: [] as any,
      day_selected: false,
      addressList: [] as any,
      courseListData: [],
      btnCheck: false,
      companies: [] as any,
      course: {
        tranche: "" as any,
        entity_id: "" as any,
        institute_info_id: "",
      } as any,
      employee: {
        address_id: "",
        company_id: "",
        status: "",
        notes: "",
        agent_id: "",
        application_id: "",
      } as any,
      courseHeader: [
        {
          name: "Applicant_id.",
          key: "applicant_id",
          sortable: true,
        },
        {
          name: "Applicant_name",
          key: "applicant_name",
          sortable: true,
          width: "200px",
        },
        {
          name: "Address_Type.",
          key: "type",
          sortable: true,
        },
        {
          name: "Address",
          key: "address",
          sortable: true,
          width: "200px",
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
        {
          name: "Assigned Agent Name",
          key: "assign_agent",
          sortable: true,
        },
        {
          name: "Agent",
          key: "agent_name",
          sortable: true,
          width: "200px",
        },
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "200px",
        },
      ],
      courseList: [],
      load: false,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      actionApprove: false,
      actionEdit: false,
      actionDelete: false,
      adminCheck: false,

      selectObj: {
        id: "select",
        entity_short_name: "Select All Training Partner",
        entity_name: "Select All Training Partner",
      } as any,
      UnselectObj: {
        id: "unselect",
        entity_short_name: "Unselect All Training Partner",
        entity_name: "Unselect All Training Partner",
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
      entityIds: [] as any,
    };
  },
  async created() {
    await this.getCompany();
    let company = VueCookieNext.getCookie("_company_id");
    if (company !== "null") {
      this.employee.company_id = company;
      this.AddressInfoList();
    }
    this.emitter.on("course-updated", async () => {
      await this.AddressInfoList();
    });
    // this.checkAdmin();
    // await this.actioncheck();
  },
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem("menu") || "{}");
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == "Training Institute") {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === "Approve") {
                this.actionApprove = true;
              }
              if (actions[j].action_name === "Edit") {
                this.actionEdit = true;
              }
              if (actions[j].action_name === "Delete") {
                this.actionDelete = true;
              }
            }
          }
        }
      }
    },
    formatDate(dateString) {
      return dateString ? format(new Date(dateString), "dd-MM-yyyy") : "";
    },
    checkAdmin() {
      if (VueCookieNext.getCookie("_seip_entity_type") == 1004) {
        let user = VueCookieNext.getCookie("_seip_user");
        console.log(
          user.employee.entity_organogram[0].entitytyperole.role_title
        );
        if (
          user.employee.entity_organogram[0].entitytyperole.role_title ==
          "Admin"
        ) {
          this.adminCheck = true;
        }
      }
    },

    async printPdf() {
      let formData = new FormData();
      formData.set("entity_id", this.course.entity_id);
      formData.set("tranche", this.course.tranche);
      formData.set("institute_info_id", this.course.institute_info_id);
      axios
        .post(`${this.VUE_APP_API_URL}/api/course/course_list_print`, formData)
        .then((response) => {
          document.write(response.data);
          window.print();
          //open the new window and write your HTML to it
        });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      formData.set("entity_id", this.course.entity_id);
      formData.set("tranche", this.course.tranche);
      formData.set("institute_info_id", this.course.institute_info_id);

      await ApiService.post("course/course_list_pdf", formData)
        .then((response) => {
          Swal.fire({
            title: "Download Successfull!",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "View/Download PDF",
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, "_blank");
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      formData.set("entity_id", this.course.entity_id);
      formData.set("tranche", this.course.tranche);
      formData.set("institute_info_id", this.course.institute_info_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/course/course_list_expot`,
          formData,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Courselist.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async formSubmit() {
      this.loading = true;
      let form = new FormData();

      form.set("addressList", JSON.stringify(this.addressList));
      let user_id = VueCookieNext.getCookie("_cpv_user");
      form.set("user_id", user_id.id);

      await ApiService.post("address/agent_mapping", form)
        .then((response) => {
          this.loading = false;
          if (response?.data?.status == "success") {
            Swal.fire({
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.addressList = [];
              // this.showcalendarDetails = false;
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    assign(id) {
      Swal.fire({
        title: "Are you sure you want to anassign this address?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let form = new FormData();
          form.set("addressList", JSON.stringify(this.addressList));
          let user_id = VueCookieNext.getCookie("_cpv_user");
          form.set("user_id", user_id.id);
          ApiService.post("address/single-assign", form)
            .then((response) => {
              this.loading = false;
              //this.courseInfo();
              Swal.fire("assigned!", response.data.message, "success");
              this.emitter.emit("course-updated", true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    filterEntity(entity) {
      this.trainingInstitute();
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes("select")) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.association.shift();

        this.association.forEach((val, index) => {
          if (val.id != "select") {
            this.course.entity_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.association = [];
        this.association.push(this.UnselectObj);
      }
      if (entity.includes("unselect")) {
        this.associationData = [];
        //unshift koro select obj
        this.association = [];

        this.association = this.tempAssociation;
        this.association.unshift(this.selectObj);
        this.course.entity_id = "";
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },
    async btnCheckStatus() {
      this.btnCheck = true;
    },
    async getTranche() {
      this.load = true;
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranche = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    Unassign(id) {
      Swal.fire({
        title: "Are you sure you want to Unassign this address?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get("address/status-change?address_id=" + id + "&status=3")
            .then((response) => {
              //this.courseInfo();
              Swal.fire("Unassigned!", response.data.message, "success");
              this.emitter.emit("course-updated", true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    batchrunning() {
      Swal.fire({
        text: "Batch Already Running!",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    async getCompany() {
      let company = VueCookieNext.getCookie("_company_id");
      let data = "";
      if (company !== "null") {
        data = company;
      }

      await ApiService.get("company/list?company=" + data)
        .then((response) => {
          this.companies = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      this.load = true;
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.association = response.data.data;
          this.tempAssociation = this.association;

          this.association.forEach((val, index) => {
            this.tempId.push(val.id);
          });
          this.association.unshift(this.selectObj);
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async trainingInstitute() {
      this.entityIds = this.course.entity_id;
      if (this.course.entity_id.includes("select")) {
        this.entityIds = this.tempId;
      }
      if (this.course.entity_id.includes("unselect")) {
        this.entityIds = -1;
      }

      let institute_info_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }

      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          "?entity_id=" +
          this.entityIds +
          "&institute_info_id=" +
          institute_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async agentList() {
      this.load = true;
      let company_id = "";
      if (VueCookieNext.getCookie("_company_id") !== "null") {
        company_id = VueCookieNext.getCookie("_company_id");
      } else {
        company_id = this.employee.company_id;
      }
      await ApiService.get(
        "employee/list?company=" + company_id + "&type=" + "4"
      )
        .then((response) => {
          this.load = false;
          this.agent = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
      // } else {
      //   Swal.fire({
      //     title: 'Please Select Tranche!',
      //     icon: 'warning',
      //     buttonsStyling: false,
      //     confirmButtonText: 'Close',
      //     customClass: {
      //       confirmButton: 'btn btn-danger',
      //     },
      //   });
      // }
    },

    async AddressInfoList() {
      let company_id = "";
      if (VueCookieNext.getCookie("_company_id") !== "null") {
        company_id = VueCookieNext.getCookie("_company_id");
      } else {
        company_id = this.employee.company_id;
      }
      this.load = true;
      await ApiService.get(
        "address/list?company_id=" +
          company_id +
          "&status=" +
          this.employee.status +
          "&application_id=" +
          this.employee.application_id
      )
        .then((response) => {
          this.agentList();
          this.load = false;
          this.addressList = response.data.data;
          for (let i = 0; i < this.addressList.length; i++) {
            this.addressList[i].day_selected =
              this.addressList[i].status === 3 ? true : false;
          }
          this.componentKey += 1;
          this.courseInfoData = true;
        })
        .catch(({ response }) => {
          console.log(response);
        });
      // } else {
      //   Swal.fire({
      //     title: 'Please Select Tranche!',
      //     icon: 'warning',
      //     buttonsStyling: false,
      //     confirmButtonText: 'Close',
      //     customClass: {
      //       confirmButton: 'btn btn-danger',
      //     },
      //   });
      // }
    },

    async editCourse(data) {
      this.emitter.emit("course-updated-drawer", data);
    },
    async copyCourse(data) {
      this.emitter.emit("course-copy-drawer", data);
    },
    // async courseLog(data) {
    //   await ApiService.get("course/edit/" + data.id)
    //     .then((response) => {
    //       this.load = false;
    //       if (response.data.data) {
    //         this.load = false;
    //         return response.data.data;
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("course/delete/" + `${id}`)
            .then((response) => {
              this.emitter.emit("course-updated", true);

              Swal.fire("Deleted!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
